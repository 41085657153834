<template>
  <div tabindex="0" role="tabpanel" id="table-1" aria-label="Formulář pro vyplnění osoby 1" class="s-form__tabpanel">
    <!-- Only for register person -->
    <!--      <fieldset class="s-form__fieldset">
        <legend class="s-form__legend">Vyberte osobu</legend>
        <div class="s-form__col">
          <label for="person" class="s-form__label">
            Osoba
            <span class="s-form__check"> </span>
          </label>
          <div class="s-form__select-box">
            &lt;!&ndash; dat sem jen, kdyz bude spravne vypnene policko &ndash;&gt;
            <span class="s-u-visualy-hidden"> Správně vyplněné políčko </span>
            <select name="person" id="person" class="s-form__input s-form__input&#45;&#45;select">
              <option value="">Bedřich Novotný</option>
              <option value="">Klára Novotná</option>
            </select>
          </div>
        </div>
      </fieldset>-->
    <!-- END  Only for register person-->
    <fieldset v-if="v$ && v$.formData" class="s-form__fieldset">
      <legend class="s-form__legend">Základní údaje</legend>
      <div class="s-form__col">
        <label for="name" class="s-form__label">
          Jméno
          <span v-if="v$.formData.name.$dirty && !v$.formData.name.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.name.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.name.$dirty && !v$.formData.name.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.name.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="name"
            id="name"
            class="s-form__input"
            v-model="formData.name"
            @blur="v$.formData.name.$touch"
          />
        </div>
        <div v-if="v$.formData.name.$error" class="s-form__error-text">
          <p>Musíte vyplnit jméno</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="surname" class="s-form__label">
          Příjmení
          <span v-if="v$.formData.surname.$dirty && !v$.formData.surname.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.surname.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.surname.$dirty && !v$.formData.surname.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.surname.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="surname"
            id="surname"
            class="s-form__input"
            v-model="formData.surname"
            @blur="v$.formData.surname.$touch"
          />
        </div>
        <div v-if="v$.formData.surname.$error" class="s-form__error-text">
          <p>Musíte vyplnit příjmení</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="date" class="s-form__label">
          Datum narození
          <span v-if="v$.formData.birthdate.$dirty && !v$.formData.birthdate.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.birthdate.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.birthdate.$dirty && !v$.formData.birthdate.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.birthdate.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="date"
            name="date"
            id="date"
            class="s-form__input s-form__input--date"
            v-model="formData.birthdate"
            @blur="v$.formData.birthdate.$touch"
          />
        </div>
        <div v-if="v$.formData.birthdate.$error" class="s-form__error-text">
          <p>Musíte vyplnit datum narození</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="number" class="s-form__label">
          Rodné číslo
          <span v-if="v$.formData.nationalid.$dirty && !v$.formData.nationalid.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.nationalid.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.nationalid.$dirty && !v$.formData.nationalid.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.nationalid.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="number"
            id="number"
            class="s-form__input"
            v-model="formData.nationalid"
            @blur="v$.formData.nationalid.$touch"
          />
        </div>
        <div v-if="v$.formData.nationalid.$error" class="s-form__error-text">
          <p>Musíte vyplnit rodné číslo</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="country" class="s-form__label">
          Národnost
          <span v-if="v$.formData.nationality.$dirty && !v$.formData.nationality.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.nationality.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__select-box">
          <span v-if="v$.formData.nationality.$dirty && !v$.formData.nationality.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.nationality.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <select
            name="country"
            id="country"
            class="s-form__input s-form__input--select"
            v-model="formData.nationality"
            @blur="v$.formData.nationality.$touch"
          >
            <option value="CZ">Česká</option>
            <option value="SK">Slovenská</option>
          </select>
        </div>
        <div v-if="v$.formData.nationality.$error" class="s-form__error-text">
          <p>Musíte vyplnit národnost</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="insurance" class="s-form__label">
          Pojišťovna
          <span v-if="v$.formData.insurance.$dirty && !v$.formData.insurance.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.insurance.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__select-box">
          <span v-if="v$.formData.insurance.$dirty && !v$.formData.insurance.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.insurance.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <select
            name="insurance"
            id="insurance"
            class="s-form__input s-form__input--select"
            v-model="formData.insurance"
            @blur="v$.formData.insurance.$touch"
          >
            <option value="VZP">VZP</option>
            <option value="OZP">OZP</option>
          </select>
        </div>
        <div v-if="v$.formData.insurance.$error" class="s-form__error-text">
          <p>Musíte vyplnit pojišťovnu</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="insurance" class="s-form__label">
          Cestovní doklad
          <span v-if="v$.formData.document.$dirty && !v$.formData.document.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.document.$error" class="s-form__fail"></span>
        </label>
        <div class="s-form__input-box">
          <input
            type="text"
            name="document"
            id="document"
            class="s-form__input"
            v-model="formData.document"
            @blur="v$.formData.document.$touch"
          />
        </div>
        <div v-if="v$.formData.document.$error" class="s-form__error-text">
          <p>Musíte vyplnit cestovní doklad</p>
        </div>
      </div>
    </fieldset>

    <fieldset v-if="v$ && v$.formData" class="s-form__fieldset">
      <legend class="s-form__legend">Kontaktní údaje</legend>
      <div class="s-form__col">
        <label for="tel" class="s-form__label">
          Telefon
          <span v-if="v$.formData.phone.$dirty && !v$.formData.phone.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.phone.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.phone.$dirty && !v$.formData.phone.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.phone.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="tel"
            id="tel"
            class="s-form__input"
            v-model="formData.phone"
            @blur="v$.formData.phone.$touch"
          />
        </div>
        <div v-if="v$.formData.phone.$error" class="s-form__error-text">
          <p>Musíte vyplnit telefon</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="email" class="s-form__label">
          E-mail
          <span v-if="v$.formData.email.$dirty && !v$.formData.email.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.email.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.email.$dirty && !v$.formData.email.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.email.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="email"
            name="email"
            id="email"
            class="s-form__input"
            v-model="formData.email"
            @blur="v$.formData.email.$touch"
          />
        </div>
        <template v-if="v$.formData.email.$error">
          <div v-if="v$.formData.email.required.$invalid" class="s-form__error-text">
            <p>Musíte vyplnit e-mail</p>
          </div>
          <div v-else-if="v$.formData.email.email.$invalid" class="s-form__error-text">
            <p>E-mail nemá správný tvar</p>
          </div>
        </template>
      </div>

      <div class="s-form__col">
        <label for="street" class="s-form__label">
          Ulice a číslo
          <span v-if="v$.formData.street.$dirty && !v$.formData.street.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.street.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.street.$dirty && !v$.formData.street.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.street.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="street"
            id="street"
            class="s-form__input"
            v-model="formData.street"
            @blur="v$.formData.street.$touch"
          />
        </div>
        <div v-if="v$.formData.street.$error" class="s-form__error-text">
          <p>Musíte vyplnit ulici</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="city" class="s-form__label">
          Město
          <span v-if="v$.formData.city.$dirty && !v$.formData.city.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.city.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.city.$dirty && !v$.formData.city.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.city.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="city"
            id="city"
            class="s-form__input"
            v-model="formData.city"
            @blur="v$.formData.city.$touch"
          />
        </div>
        <div v-if="v$.formData.city.$error" class="s-form__error-text">
          <p>Musíte vyplnit město</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="psc" class="s-form__label">
          PSČ
          <span v-if="v$.formData.zip.$dirty && !v$.formData.zip.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.zip.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.zip.$dirty && !v$.formData.zip.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.zip.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="psc"
            id="psc"
            class="s-form__input"
            v-model="formData.zip"
            @blur="v$.formData.zip.$touch"
          />
        </div>
        <div v-if="v$.formData.zip.$error" class="s-form__error-text">
          <p>Musíte vyplnit PSČ</p>
        </div>
      </div>

      <div class="s-form__col">
        <label for="countrycontact" class="s-form__label">
          Země
          <span v-if="v$.formData.country.$dirty && !v$.formData.country.$error" class="s-form__check"> </span>
          <span v-else-if="v$.formData.country.$error" class="s-form__fail"> </span>
        </label>
        <div class="s-form__input-box">
          <span v-if="v$.formData.country.$dirty && !v$.formData.country.$error" class="s-u-visualy-hidden">
            Správně vyplněné políčko
          </span>
          <span v-else-if="v$.formData.country.$error" class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
          <input
            type="text"
            name="countrycontact"
            id="countrycontact"
            class="s-form__input"
            v-model="formData.country"
            @blur="v$.formData.country.$touch"
          />
        </div>
        <div v-if="v$.formData.country.$error" class="s-form__error-text">
          <p>Musíte vyplnit zemi</p>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { computed, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CustomersFormComponent',

  props: {
    customer: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    type: {
      type: String,
      default: 'order',
    },
  },

  setup(props) {
    const state = reactive({
      formData: props.customer,
    });

    const rules = computed(() => ({
      formData: {
        name: { required },
        surname: { required },
        birthdate: { required },
        nationalid: { required },
        nationality: { required },
        insurance: { required },
        phone: { required },
        email: { required, email },
        street: { required },
        city: { required },
        zip: { required },
        country: { required },
        document: { required },
      },
    }));

    const v$ = useVuelidate(rules, state);

    return { ...state, v$ };
  },

  computed: {
    ...mapGetters({ getFormData: 'orders/getFormData', me: 'me/getMe' }),
  },

  watch: {
    formData: {
      deep: true,
      handler() {
        if (this.type !== 'edit') {
          this.updateCustomerFormData();
        }
        this.$emit('is-valid', !this.v$.formData.$invalid);
      },
    },
  },

  methods: {
    ...mapActions({ updateFormData: 'orders/updateFormData' }),

    updateCustomerFormData() {
      const customers = [...this.getFormData.customers];
      customers[this.index] = this.formData;

      this.updateFormData({
        ...this.getFormData,
        customers,
      });
    },
  },

  created() {
    this.$emit('is-valid', !this.v$.formData.$invalid);
  },

  mounted() {
    if (this.type === 'edit') {
      this.$emit('getCustomerData', this.formData);
    }
    if (this.me) {
      this.v$.formData.$touch();
    }
  },
};
</script>
